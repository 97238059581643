import React, { useContext, useState } from 'react'
import { graphql } from 'gatsby'
import { Flex, Box } from 'reflexbox'

import StoreContext from '~/context/StoreContext'
import SEO from '~/components/seo'
import Container from '../../components/ui/Container'
import ProductImage from '../../components/ui/ProductImage'
import Heading from '../../components/ui/Heading'
import Button from '../../components/ui/Button'
import Text from '../../components/ui/core/Text'
import TextLink from '../../components/ui/TextLink'
import { productTypes } from '../../constants'
import Panel from '../../components/ui/Panel'
import CustomOrderForm from '../../components/CustomOrderForm'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  const collection = data.shopifyCollection
  const {
    addVariantToCart,
    store: { checkout, adding },
  } = useContext(StoreContext)

  const isCustom = product.productType === productTypes.CUSTOM

  const isBespoke = collection?.handle === 'bespoke'

  const hasProduct = checkout.lineItems.find(
    item => item.variant.id === product.variants[0].shopifyId
  )

  const [isAdded, setIsAdded] = useState(false)

  const isAvailable = product.variants[0].availableForSale

  const handleAddToCart = productVariant => {
    addVariantToCart(productVariant.shopifyId, 1)
    setIsAdded(true)
  }

  const getButtonMessage = () => {
    if (isAdded || hasProduct) {
      return 'Added!'
    }

    return `Add to cart — $${product.variants[0].price}`
  }

  return (
    <>
      <SEO
        title={product.title}
        description={product.description}
        image={product.images[0].originalSrc}
      />
      <Container>
        <Flex fontSize={3} my={4} flexWrap="wrap">
          <Box width={[1, 1 / 2, 1 / 2]} pr={[0, 3, 4, 5]}>
            {product.images.map(image => (
              <ProductImage
                fluid={image.localFile.childImageSharp.fluid}
                key={image.id}
                alt={product.title}
                mb={4}
                sizes="680px, (max-width: 1024px) 640px, (max-width: 1440px) 1060px"
              />
            ))}
          </Box>
          <Box width={[1, 1 / 2, 1 / 2]}>
            <Heading level={2}>{product.title}</Heading>
            {!isCustom && isAvailable && (
              <span>${product.variants[0].price}</span>
            )}
            <Text
              as="div"
              mt={4}
              mb={3}
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
            {isCustom ? (
              <CustomOrderForm mt={4} />
            ) : (
              <>
                {collection && !isBespoke && (
                  <Panel mb={4}>
                    <Heading level={5} mb={2}>
                      About the {collection.title} collection
                    </Heading>
                    <Text fontSize={2}>{collection.description}</Text>
                    <TextLink color="blue" to="/about" fontSize={2}>
                      Find out more about Prospect Leather
                    </TextLink>
                  </Panel>
                )}
                {isAvailable ? (
                  <>
                    <Button
                      disabled={!isAvailable || adding || isAdded || hasProduct}
                      onClick={() => handleAddToCart(product.variants[0])}
                      mb={2}
                      mt={4}
                    >
                      {getButtonMessage()}
                    </Button>
                    {collection && (
                      <Text mt={3} fontSize={1}>
                        Like this item but want to make some tweaks?{' '}
                        <TextLink
                          color="blue"
                          to={`/product/custom-${collection.handle}`}
                        >
                          Order a custom-made version.
                        </TextLink>
                      </Text>
                    )}
                  </>
                ) : (
                  <Button
                    to={
                      collection
                        ? `/product/custom-${collection.handle}`
                        : '/custom'
                    }
                    mb={2}
                    mt={4}
                  >
                    Order a custom{' '}
                    {isBespoke || !collection ? 'version' : collection.title}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1060) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    shopifyCollection(products: { elemMatch: { handle: { eq: $handle } } }) {
      title
      description
      handle
    }
  }
`

export default ProductPage
